import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Page from './Page';
import { IPdf } from './interface/IPdf';
import './Site.css';

interface SiteGridProps {
  data: IPdf[];
  rotate: (id: number, rotation: number) => void;
  delete: (id: number) => void;
  action: (id: number, move: string) => void;
}

export default function SiteGrid({ data, rotate, delete: del, action }: SiteGridProps) {
  const [sortedData, setSortedData] = useState(data);
  const [tools, setTools] = useState<number>(-1);

  useEffect(() => {
    const ord = data.sort((a: IPdf, b: IPdf) => a.order - b.order);
    setSortedData(ord);
  }, [data]);

  const delTool = (id: number) => {
    setTools(id);
  };

  return (
    <div style={{ padding: 20 }}>
      <Grid container spacing={3} className="root">
        {sortedData.map((it: IPdf) => (
          <Grid item key={it.id} xs={12} md={6} lg={3} className="item">
            <Page
              id={it.id}
              image={it.image}
              order={it.order}
              rotate={rotate}
              delete={del}
              action={action}
              delTools={delTool}
              tools={tools}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
