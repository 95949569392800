import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import './App.css';
import SiteGrid from './Site';
import Dropzone from 'react-dropzone';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { CircularProgress } from '@material-ui/core';

// @ts-ignore
import PDFJS from 'pdfjs-dist/webpack';
import { PDFDocument } from 'pdf-lib';
import { degrees } from 'pdf-lib'

import { StyleSheet, css } from 'aphrodite';

import { IPdf, ImagePdf } from './interface/IPdf';

export default function App() {
  const [data, setData] = useState<IPdf[] | []>([]);
  const [pdf, setPdf] = useState(false);
  const [counter, setCounter] = useState(0);
  const [pdfB, setPdfB] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [compress, setCompress] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [namePdf, setNamePdf] = useState('AwesomePdf');

  function compressImage(base64: any, maxWidth: number, maxHeight: number) {
    const canvas = document.createElement('canvas')
    const img = document.createElement('img')

    return new Promise((resolve, reject) => {
      img.onload = function () {
        let width = maxWidth
        let height = maxHeight

        if (width > height) {
          if (width > maxWidth) {
            height = Math.round((height *= maxWidth / width))
            width = maxWidth
          }
        } else {
          if (height > maxHeight) {
            width = Math.round((width *= maxHeight / height))
            height = maxHeight
          }
        }
        canvas.width = width
        canvas.height = height

        const ctx = canvas.getContext('2d')
        if (ctx) {
          ctx.drawImage(img, 0, 0, width, height)
          resolve(canvas.toDataURL('image/jpeg', 0.7))
        } else {
          reject('err')
        }

      }
      img.onerror = function (err) {
        reject(err)
      }
      img.src = base64
    })
  }
  function handleClose() {
    setDialog(false);
  }
  function openSave() {
    setDialog(true);
  }
  const rotate = (id: number, rotation: number) => {
    const d: IPdf[] = data;
    const o = d.map((item: IPdf) => {
      if (item.id === id) {
        item.rotation = rotation;
        return item;
      } else {
        return item;
      }
    });
    setData(o);
  }
  const handler = (id: number, move: string) => {
    const da: IPdf[] = data;

    let d: any = {};
    d = data.find((item: IPdf) => {
      return item.id === id;
    });
    const copyD: IPdf = { ...d };

    const o = da.map((item: IPdf) => {
      if (move === 'right') {
        if (item.order === (copyD.order + 1)) {
          item.order -= 1;
          return item;
        } else if (item.id === copyD.id && item.order !== data.length) {
          item.order += 1;
          return item;
        } else {
          return item;
        }
      } else {
        if (item.order === (copyD.order - 1)) {
          item.order += 1;
          return item;
        } else if (item.id === copyD.id && item.order !== 0) {
          item.order -= 1;
          return item;
        } else {
          return item;
        }
      }
    });
    const ord = o.sort((a: any, b: any) => {
      return a.order - b.order;
    });
    setData(ord);
  }
  const del = (id: number) => {
    const d: IPdf[] = data;

    let it: IPdf;
    d.map((item: IPdf) => {
      if (item.id === id) {
        it = item;
        const index = d.indexOf(item);
        if (index !== -1) data.splice(index, 1);
      }
      return item;
    });
    const o = d.map((item: IPdf) => {
      if (item.order > it.order) {
        item.order -= 1;
        return item;
      } else {
        return item;
      }
    })
    setData(o);
  }
  function getPage(pdf: any, i: number) {
    return new Promise((resolve, reject) => {
      //pdf.getData().then((d:any) => console.log(d));
      pdf.getPage(i + 1).then((page: any) => {
        // page.getTextContent().then((d:any) => console.log(d));
        const scale = 1.5;
        const viewport = page.getViewport(scale);
        const canvas = document.createElement('canvas');
        const canvasContext = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        page.render({
          canvasContext, viewport
        }).promise.then(() => {
          const size = Object.keys(page.objs._objs).length;
          if (size === 1 && page.objs._objs[Object.keys(page.objs._objs)[0]] && page.objs._objs[Object.keys(page.objs._objs)[0]].data && page.objs._objs[Object.keys(page.objs._objs)[0]].data.nodeName === 'IMG') {
            const data = page.objs._objs[Object.keys(page.objs._objs)[0]].data;
            const imgBlob = fetch(data.src).then(async (response: any) => {
              const d = await response.blob();
              return d;
            });
            imgBlob.then((blob: any) => {
              var reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = async () => {
                var base64data = reader.result;
                const d: any = await compressImage(base64data, 2.5 * page.view[2], 2.5 * page.view[3]);
                const image: ImagePdf = {
                  x: data.x,
                  y: data.y,
                  width: page.view[2],
                  height: page.view[3],
                  name: Object.keys(page.objs._objs)[0],
                  base64: d,
                }
                resolve({
                  id: i,
                  order: page.pageNumber,
                  image: canvas.toDataURL('image/png'),
                  pdfId: pdf._pdfInfo.fingerprint,
                  pdfOrder: counter,
                  originalOrder: page.pageNumber,
                  rotation: page.rotation ? page.rotation : 0,
                  imageSrc: image,
                });
              }
            });
          } else {
            resolve({
              id: i,
              order: page.pageNumber,
              image: canvas.toDataURL('image/png'),
              pdfId: pdf._pdfInfo.fingerprint,
              pdfOrder: counter,
              originalOrder: page.pageNumber,
              rotation: page.rotation ? page.rotation : 0,
              imageSrc: null,
            });
          }
        })
      })
    })
  }
  function renderPdf(binaryStr: any) {
    setCounter(counter + 1);
    const p = pdfB;
    p.push(binaryStr);
    setPdfB(p);
    //const pdfDoc = await PDFDocument.load(binaryStr)

    PDFJS.getDocument(binaryStr).promise.then((pdf: any) => {
      const pages: any[] = [];
      for (let i = 0; i < pdf.numPages; i++) {
        pages.push(getPage(pdf, i));
      }
      Promise.all(pages).then((d) => {
        if (data.length === 0) {
          setData(d);
        } else {
          const last = data[data.length - 1];
          /*const last = this.state.data.sort((a:any, b:any) => {
            return a.order-b.order;
          }); */
          const ePages = d.map((page: IPdf) => {
            page.id = last.order + page.id;
            page.order = last.order + page.order;
            return page;
          });
          let dataN = [...data];
          dataN = dataN.concat(ePages);
          setData(dataN);
        }
        setLoading(false);
        setPdf(true);
      })
    });
  }
  async function exportPdf() {
    const d: IPdf[] = data;
    const pdfs = pdfB;
    const newPdf = await PDFDocument.create();
    d.sort((a: any, b: any) => (a.order > b.order) ? 1 : -1);
    const pages = d.map(async (page: IPdf) => {
      if (compress && page.imageSrc) {
        const tempPdf = await PDFDocument.create();
        const p = tempPdf.addPage();
        const jpgImage = await tempPdf.embedJpg(page.imageSrc.base64)
        p.drawImage(jpgImage, {
          x: page.imageSrc.x,
          y: page.imageSrc.y,
          width: page.imageSrc.width,
          height: page.imageSrc.height,
        })
        console.log(page.rotation);
        p.setRotation(degrees(page.rotation));
        const [firstDonorPage] = await newPdf.copyPages(tempPdf, [0]);
        return { order: page.order, page: firstDonorPage };
      } else {
        const doc = pdfs[page.pdfOrder]; //was page.pdfOrder - 1
        const firstDonorPdfDoc = await PDFDocument.load(doc);
        const p = firstDonorPdfDoc.getPages()
        p[page.originalOrder - 1].setRotation(degrees(page.rotation));
        const [firstDonorPage] = await newPdf.copyPages(firstDonorPdfDoc, [page.originalOrder - 1]);
        return { order: page.order, page: firstDonorPage };
      }
    });
    Promise.all(pages).then(async (d: any) => {
      d.map((page: any) => {
        return newPdf.insertPage(page.order - 1, page.page);
      })
      const pdfBytes = await newPdf.save();
      var blob = new Blob([pdfBytes], { type: "application/pdf" });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      var fileName = namePdf + '.pdf';
      link.download = fileName;
      link.click();

      // @ts-ignore
      function blobToBase64(blob: any) {
        return new Promise((resolve, _) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
      }

      const data = await blobToBase64(blob);
      console.log("data", data);
      // @ts-ignore
      window.ReactNativeWebView.postMessage(JSON.stringify({ "link": data, "name": fileName }));

    });
  }

  const styles = StyleSheet.create({
    drop: {
      textAlign: 'center',
      borderStyle: 'dashed',
      borderColor: 'orange',
      borderWidth: 0,
      color: 'white',
      cursor: 'pointer',
      flexGrow: 3,
      height: '100%',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 10,
    },
    toolbox: {
      backgroundColor: '#282c34',
      position: 'sticky',
      bottom: 0,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    firstTool: {
      height: '100vh'
    }
  })
  return (
    <div className="App">
      <Dialog onClose={() => handleClose()} aria-labelledby="export property" open={dialog && pdf} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle id="simple-dialog-title">Export properties</DialogTitle>
        <DialogContent>
          <FormGroup>
            <FormControlLabel control={<TextField
              autoFocus
              margin="dense"
              id="name"
              label="Export name"
              type="text"
              onChange={(e: any) => setNamePdf(e.target.value)}
              fullWidth
            />} label="" />
            <FormControlLabel control={<Switch checked={compress} onChange={() => {
              if (!compress) {
                setCompress(true);
              } else {
                setCompress(false);
              }
            }} />} label="Compress" />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => exportPdf()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {pdf ? <SiteGrid data={data} rotate={rotate} delete={del} action={handler} /> : null}
      <div className={css(styles.toolbox, !pdf ? styles.firstTool : false)}>
        <Dropzone multiple={false} accept="application/pdf"
          onDrop={acceptedFiles => {
            acceptedFiles.forEach((file) => {
              const reader = new FileReader()
              reader.onabort = () => console.log('file reading was aborted')
              reader.onerror = () => console.log('file reading has failed')
              reader.onload = () => {
                setLoading(true);
                const binaryStr = reader.result;
                renderPdf(binaryStr);
              }
              reader.readAsArrayBuffer(file)
            })
          }
          }>
          {({ getRootProps, getInputProps }) => (
            <section style={{ position: 'relative', height: '90%', width: '100%', paddingRight: 20, paddingLeft: 20 }}>
              <div {...getRootProps()} style={{ height: '100%', padding: 0 }}>
                <input {...getInputProps()} />
                {!pdf ? <p className={css(styles.drop)}>Drag 'n' drop some PDF file here, or click to select file</p>
                  : <p className={css(styles.drop)}>Add another PDF file</p>}
              </div>
            </section>
          )}
        </Dropzone>

        {pdf ? <div style={{ display: 'flex', padding: 10 }}><Button variant="contained" color="primary" onClick={() => openSave()}>
          EXPORT
        </Button></div> : null}
      </div>
      {loading ? <div style={{ backgroundColor: 'rgb(234, 234, 234)', position: 'absolute', height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress size={100} /></div> : null}
    </div>
  );
}

