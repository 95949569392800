import React, { useState } from 'react';
import { Paper, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import RotateRight from '@material-ui/icons/RotateRight';
// @ts-ignore
import { fadeIn } from 'react-animations';
import { StyleSheet, css } from 'aphrodite';
import './Page.css';

interface PageProps {
  id: number;
  tools: number;
  action: (id: number, move: string) => void;
  delete: (id: number) => void;
  rotate: (id: number, rotation: number) => void;
  delTools: (id: number) => void;
  image: string;
  order: number;
}

const Page: React.FC<PageProps> = (props) => {
  const [state, setState] = useState({
    tool: false,
    fadeIn: false,
    rotation: 0,
  });

  const call = (id: number, move: string) => {
    setState({ ...state, fadeIn: true });
    props.action(id, move);
    setTimeout(() => setState({ ...state, fadeIn: false }), 3000);
  };

  const deleteItem = (id: number) => {
    props.delete(id);
  };

  const rotate = (id: number, rotation: number) => {
    const initState = { ...state };
    const rot = initState.rotation + rotation;
    setState({ ...state, rotation: rot });
    props.rotate(id, rot);
  };

  const styles = StyleSheet.create({
    bounce: {
      animationName: fadeIn,
      animationDuration: '1s',
    },
    scale: {
      transform: `scale(0.73) rotate(${state.rotation}deg)`,
    },
    rotate: {
      transform: `rotate(${state.rotation}deg)`,
    },
    paper: {
      textAlign: 'center',
      width: '100%',
      paddingTop: '141%',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: `url(${props.image}) no-repeat center center`,
      backgroundSize: 'cover',
      backgroundColor: 'rgb(255, 255, 255)',
    },
  });

  return (
    <div>
      <Paper
        className={css(styles.paper, styles.rotate, state.fadeIn ? styles.bounce : false, state.rotation % 180 === 90 ? styles.scale : false)}
        elevation={props.id === props.tools ? 5 : 1}
        onClick={() => {
          if (props.id === props.tools) {
            props.delTools(-1);
            setState({ ...state, tool: false });
          } else {
            props.delTools(props.id);
            setState({ ...state, tool: true });
          }
        }}
      ></Paper>
      {state.tool && props.id === props.tools ? (
        <div>
          <IconButton color="primary" aria-label="Move Back" onClick={() => call(props.id, 'left')}>
            <ArrowBack />
          </IconButton>
          <IconButton aria-label="delete" onClick={(e) => deleteItem(props.id)}>
            <DeleteIcon />
          </IconButton>
          <IconButton aria-label="rotate 90" onClick={(e) => rotate(props.id, 90)}>
            <RotateRight />
          </IconButton>
          <IconButton color="primary" aria-label="Move Forward" onClick={() => call(props.id, 'right')}>
            <ArrowForward />
          </IconButton>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Page;
